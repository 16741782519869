import "./set-public-path";

//jwt decode library
import jwt_decode from "jwt-decode";

//parse url parameter
const queryString = require("query-string");

export const getDataFromToken = async () => {
  var urlInfo = queryString.parseUrl(window.location.href);
  let token = urlInfo.query.token || sessionStorage.getItem("token");

  // console.log("token", token);

  if (token) {
    sessionStorage.setItem("token", token);

    return validateToken(token);
  } else {
    return { servicio: null, email: null };
  }
};

const validateToken = (token) => {
  let response, email, servicio, exp;
  var decoded = jwt_decode(token);
  email = decoded.sub;
  servicio = decoded.servicio;
  exp = decoded.exp;
  var currentDate = new Date();

  if (currentDate.getTime() > exp) {
    response = { servicio, email };
  } else {
    response = { servicio: null, email: null };
  }

  return response;
};
